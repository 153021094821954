import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '@/components/Register.vue'
import Order from '@/components/Order.vue'
import Login from '@/components/Login.vue'
import Kursovaya from '@/components/Kursovaya.vue'
import Diplom from '@/components/Diplom.vue'
import Referat from '@/components/Referat.vue'
import Dissertaciya from '@/components/Dissertaciya.vue'
import Shop from '@/components/Shop.vue'
import Goods from '@/components/Goods.vue'
import Good from '@/components/Good.vue'
import FAQ from '@/components/FAQ.vue'
import CommentsList from '@/components/CommentsList.vue'
import CommentView from '@/components/CommentView.vue'
import Article from '@/components/Article.vue'
import Working from '@/components/Working.vue'
import News from '@/components/News.vue'
import PaymentResult from '@/components/PaymentResult.vue'
import Discounts from '@/components/Discounts.vue'
import Main from '@/components/Main.vue'
import OrdersHistory from '@/components/lk/Orders.vue'
import OrderView from '@/components/lk/Order.vue'
import Pay from '@/components/lk/Pay.vue'
import OrderAccept from '@/components/lk/OrderAccept.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/register/', component: Register, meta: { title: 'Зарегистрироваться' } },
  { path: '/order/new.html', component: Order, meta: { title: 'Форма заказа' } },
  { path: '/login/', component: Login, meta: { title: 'Войти' } },
  { path: '/faq/', component: FAQ, meta: { title: 'Вопросы?', description: 'У вас есть вопрос по заказу курсовой, диплома или реферата?' } },
  { path: '/kursovaya-za-1-den.html', component: Kursovaya, meta: { title: 'Курсовая за 1 день - поможем написать!', description: 'Помощь с курсовой работой по любой теме. Срок 1-2 дня. Более 1000 дисциплин и команда опытных преподавателей.' } },
  { path: '/diplom-srochnaya-pomoshch-za-1-den.html', component: Diplom, meta: { title: 'Диплом - срочная помощь за 1 день', description: 'Диплом для дипломника и бакалавра. Выполняем срочные работы. Ведём студента до защиты.' } },
  { path: '/referat-za-den.html', component: Referat, meta: { title: 'Заказать реферат - поможем написать!', description: 'Помощь в написании реферата с оригинальностью до 90%. Опытные преподаватели и самая свежая литература.' } },
  { path: '/dissertaciya-pomozhem-napisat.html', component: Dissertaciya, meta: { title: 'Поможем написать диссертацию', description: 'Помогают доктора экономических, гуманитарных и технических наук. Исследования выполняются с учётом современных методик и разработок.' } },
  { path: '/magazin-gotovyh-rabot/:letter.html', component: Shop, meta: { title: 'Купить готовую работу', description: 'Онлайн покупка готовой уникальной работы по множеству предметов' } },
  { path: '/magazin-gotovyh-rabot/predmety/:lesson.html', name: 'goods', component: Goods, props: true, meta: { title: 'Купить готовую работу', description: 'Онлайн покупка готовой уникальной работы по множеству предметов' } },
  { path: '/magazin-gotovyh-rabot/raboty/:id-:theme.html', name: 'good', component: Good, props: true, meta: { title: 'Купить готовую работу', description: 'Онлайн покупка готовой уникальной работы по множеству предметов' } },
  { path: '/otzyvy/stranica_:id.html', name: 'comments', component: CommentsList, props: true, meta: { title: 'Отзывы Хвостов.Нет', description: 'Отзывы и предложения Хвостов.Нет' } },
  { path: '/otzyvy/otzyv_:id.html', name: 'comment', component: CommentView, props: true, meta: { title: 'Отзывы Хвостов.Нет', description: 'Отзывы и предложения Хвостов.Нет' } },
  { path: '/cursovaya-rabota-s-prakticheskoi-chastju-na-primere-organizacii.html', component: Article, props: { path: 'cursovaya-rabota-s-prakticheskoi-chastju-na-primere-organizacii' }, meta: { title: 'Курсовая работа с практической частью на примере организации' } },
  { path: '/kursovaya-po-pravu-osobennosti-napisaniya.html', component: Article, props: { path: 'kursovaya-po-pravu-osobennosti-napisaniya' }, meta: { title: 'Курсовая по праву - особенности написания' } },
  { path: '/primernyj-plan-kursovoj-po-upravleniyu-predpriyatiem.html', component: Article, props: { path: 'primernyj-plan-kursovoj-po-upravleniyu-predpriyatiem' }, meta: { title: 'Курсовая по управлению предприятием - составляем план' } },
  { path: '/kak-vybrat-rukovoditelya-kursovoj-po-upravleniyu-predpriyatiem.html', component: Article, props: { path: 'kak-vybrat-rukovoditelya-kursovoj-po-upravleniyu-predpriyatiem' }, meta: { title: 'Курсовая по управлению - выбираем руководителя' } },
  { path: '/kak-vybrat-rukovoditelya-kursovoj-po-upravleniyu-predpriyatiem-chast-2.html', component: Article, props: { path: 'kak-vybrat-rukovoditelya-kursovoj-po-upravleniyu-predpriyatiem-chast-2' }, meta: { title: 'Курсовая по управлению - выбираем руководителя. Часть 2' } },
  { path: '/kak-stat-studentom-kotoryj-napishet-luchshe-vsekh-kursovuyu-po-upravleniyu-organizaciej.html', component: Article, props: { path: 'kak-stat-studentom-kotoryj-napishet-luchshe-vsekh-kursovuyu-po-upravleniyu-organizaciej' }, meta: { title: 'Как стать студентом, который напишет лучше всех курсовую по управлению организацией' } },
  { path: '/kak-najti-dannye-chtoby-napisat-kursovuyu-po-upravleniyu-firmoj.html', component: Article, props: { path: 'kak-najti-dannye-chtoby-napisat-kursovuyu-po-upravleniyu-firmoj' }, meta: { title: 'Как найти данные, чтобы написать курсовую по управлению фирмой' } },
  { path: '/skolko-nuzhno-gotovit-horoshuyu-kursovuyu-po-upravleniyu-kompaniej.html', component: Article, props: { path: 'skolko-nuzhno-gotovit-horoshuyu-kursovuyu-po-upravleniyu-kompaniej' }, meta: { title: 'Сколько нужно готовить хорошую курсовую по управлению компанией?' } },
  { path: '/kak-ehffektivno-polzovatsya-istochnikami-informacii-kotorye-trebuet-kursovaya-po-upravleniyu-predpriyatiem.html', component: Article, props: { path: 'kak-ehffektivno-polzovatsya-istochnikami-informacii-kotorye-trebuet-kursovaya-po-upravleniyu-predpriyatiem' }, meta: { title: 'Как эффективно пользоваться источниками информации, которые требует курсовая по управлению предприятием' } },
  { path: '/chego-ne-dolzhno-byt-v-kursovoj-rabote-po-upravleniyu-predpriyatiem.html', component: Article, props: { path: 'chego-ne-dolzhno-byt-v-kursovoj-rabote-po-upravleniyu-predpriyatiem' }, meta: { title: 'Чего не должно быть в курсовой работе по управлению предприятием?' } },
  { path: '/kursovaya-rabota-po-upravleniyu-predpriyatiem-opyt-zarubezhnyh-vuzov.html', component: Article, props: { path: 'kursovaya-rabota-po-upravleniyu-predpriyatiem-opyt-zarubezhnyh-vuzov' }, meta: { title: 'Курсовая работа по управлению предприятием: опыт зарубежных ВУЗов' } },
  { path: '/working.html', component: Working, meta: { title: 'Как мы работаем', description: 'Как заказать и получить у нас курсовую, диплом или реферат - в двух словах' } },
  { path: '/news-all.html', component: News, meta: { title: 'Все новости', description: 'Новости сайта. Более 1000 дисциплин. Качество. Гарантии' } },
  { path: '/order/yandex-card-success.html', component: PaymentResult, props: { status: 'success' }, meta: { title: 'Оплата заказа', description: 'Оплата заказа' } },
  { path: '/order/yandex-card-fail.html', component: PaymentResult, props: { status: 'fail' }, meta: { title: 'Оплата заказа', description: 'Оплата заказа' } },
  { path: '/discounts.html', component: Discounts, meta: { title: 'Скидки на наши услуги', description: 'Скидки на курсовые, дипломы, рефераты. Более 1000 дисциплин. Качество. Гарантии' } },
  { path: '/', component: Main, meta: { title: 'Курсовая, диплом, реферат на заказ', description: 'Выполняем курсовые, дипломы, рефераты. Более 1000 дисциплин. Качество. Гарантии' } },
  { path: '/order/current.html', component: OrdersHistory, meta: { title: 'Личный кабинет', description: 'Личный кабинет пользователя' } },
  { path: '/order/:id', component: OrderView, props: true, meta: { title: 'Личный кабинет', description: 'Личный кабинет пользователя' } },
  { path: '/order/pay/:id', component: Pay, props: true, meta: { title: 'Личный кабинет', description: 'Личный кабинет пользователя' } },
  { path: '/order/accept/:id', component: OrderAccept, props: true, meta: { title: 'Личный кабинет', description: 'Личный кабинет пользователя' } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const defaultTitle = 'Курсовая, диплом, реферат на заказ'
const defaultDescription = 'Выполняем курсовые, дипломы, рефераты. Более 1000 дисциплин. Качество. Гарантии'

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title || defaultTitle

    const descEl = document.querySelector('head meta[name="description"]')
    if (descEl !== null) {
      descEl.setAttribute('content', to.meta.description || defaultDescription)
    }
  })
})

export default router
