import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authorized: false,
    email: '',
    discount: 0,
    phone: '',
    id: 0
  },
  mutations: {
    authorize: (state) => {
      state.authorized = true
    },
    unauthorize: (state) => {
      state.authorized = false
    },
    setEmail: (state, email: string) => {
      state.email = email
    },
    setPhone: (state, phone: string) => {
      state.phone = phone
    },
    setDiscount: (state, discount: number) => {
      state.discount = discount
    },
    setID: (state, id: number) => {
      state.id = id
    }
  },
  getters: {
    authorized: state => state.authorized,
    email: state => state.email,
    discount: state => state.discount,
    phone: state => state.phone,
    id: state => state.id
  }
})
